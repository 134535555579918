@import url(normalize.css);
@import url(swiper/swiper-bundle.css);
html {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

* {
  box-sizing: inherit;
}

img {
  max-width: 100%;
}

.res-swiper-holder {
  background-image: linear-gradient(to bottom, transparent 25%, #353434 25% 75%, transparent 75% 100%);
  margin: 5vh 0;
}

@media (orientation: portrait) {
  .res-swiper-holder {
    background-image: linear-gradient(to bottom, transparent 17vh, #353434 17vh 37vh, transparent 37vh 47vh);
    margin-bottom: 0;
    margin-top: 0;
  }
}

.swiper {
  padding: 5vh 0;
}

@media (orientation: portrait) {
  .swiper {
    padding-bottom: 37vh;
  }
}

.swiper-slide {
  height: 80vh;
  max-width: 720px;
  max-height: 720px;
  overflow: hidden;
  width: 80vh;
}

@media (orientation: portrait) {
  .swiper-slide {
    height: 40vh;
    overflow: visible;
    width: 40vh;
  }
}

.swiper-slide img {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  color: #ED6B06;
  transition: transform;
}

@media (orientation: portrait) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.-revealed .swiper-button-next {
  animation: 0.25s ease-in-out 0s 12 alternate wiggle;
}

@-moz-document url-prefix() {
  .swiper-wrapper {
    width: 0;
  }
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-8px);
  }
}

.landing {
  overflow: hidden;
}

.landing__grid {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  overflow: hidden;
  width: 100%;
}

@media (orientation: portrait) {
  .landing__grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
}

.landing__cell {
  filter: grayscale(0%);
  position: relative;
  transition: filter 0.5s;
}

.landing__cell.-effect {
  filter: grayscale(100%);
}

.landing img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.res-slide-info {
  background-image: linear-gradient(to top, #353434, transparent 40%);
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

@media screen and (orientation: portrait) {
  .res-slide-info {
    background-image: linear-gradient(to top, #353434, transparent 75%);
  }
}

.res-slide-info__h {
  font-weight: 400;
  font-size: 4vh;
  line-height: 1.25;
  margin: auto 0 0;
}

@media (orientation: portrait) {
  .res-slide-info__h {
    font-size: 2.25vh;
  }
}

.res-slide-info__h-sub {
  font-weight: 400;
  font-size: 3vh;
  line-height: 1.25;
  margin: 0.5em 0 0;
}

@media (orientation: portrait) {
  .res-slide-info__h-sub {
    font-size: 2vh;
  }
}

.res-slide-info__wrap {
  display: flex;
  flex-direction: column;
  padding: 7.5%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.res-slide-info__meta {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 2.5vh;
}

.res-slide-info__year {
  color: #ED6B06;
  font-size: 4vh;
  font-weight: 400;
  margin: 0;
}

@media (orientation: portrait) {
  .res-slide-info__year {
    font-size: 2.5vh;
  }
}

.res-slide-info__ext-link, .res-slide-info__back, .res-slide-info__btn {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid currentColor;
  color: #fff;
  cursor: pointer;
  font-family: "Noto Sans", sans-serif;
  font-size: 2vh;
  padding: 0.5em 1.5em;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
}

@media (orientation: portrait) {
  .res-slide-info__ext-link, .res-slide-info__back, .res-slide-info__btn {
    font-size: 1.5vh;
  }
}

.res-slide-info__ext-link:hover, .res-slide-info__back:hover, .res-slide-info__btn:hover {
  background-color: #fff;
  color: #353434;
  border-color: #fff;
}

.res-slide-info__more {
  background-color: #8A8A8A;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
  text-align: center;
  transform: translateY(100%);
  transition: all 0.4s;
  padding: 7.5%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

@media (orientation: portrait) {
  .res-slide-info__more {
    justify-content: flex-start;
    flex-direction: row;
    background-color: transparent;
    color: #000;
    opacity: 1;
    text-align: left;
    transform: translateY(100%);
  }
}

.-show-more .res-slide-info__more {
  opacity: 1;
  transform: translateY(0);
}

@media (orientation: portrait) {
  .-show-more .res-slide-info__more {
    transform: translateY(100%);
  }
}

.res-slide-info__text {
  font-size: 3vh;
  opacity: 0;
  transition: opacity 1s 0.4s ease-out;
}

.-show-more .res-slide-info__text {
  opacity: 1;
}

@media (orientation: portrait) {
  .res-slide-info__text {
    font-size: 2.5vh;
    opacity: 1;
    transition: opacity 0.4s 0s ease-out;
  }
}

@media (orientation: portrait) {
  .res-slide-info__btn {
    display: none;
  }
}

.res-slide-info__back-control {
  margin-top: 12.5vh;
}

@media (orientation: portrait) {
  .res-slide-info__back-control {
    display: none;
  }
}

.page-header {
  background-color: #77A22F;
  color: #fff;
  display: none;
  height: 16vh;
  padding: 0 20px;
  text-align: center;
}

.page-header h1 {
  margin: 0;
}

.page-header .title-large,
.page-header .title-small {
  display: block;
}

.page-header .title-large {
  font-size: 5.375vh;
}

.page-header .title-small {
  font-size: 2vh;
}

@media (orientation: portrait) {
  .page-header {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.title {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.title__text {
  background-color: #77A22F;
  color: #fff;
  font-size: 7.4vw;
  font-weight: 400;
  padding: 6vh 6vw;
  text-align: center;
}

.title__text span {
  display: block;
  font-size: 3vw;
}

.-scroll-away .title {
  top: auto;
}

.-scroll-away .title__text {
  align-items: center;
  display: flex;
  font-size: 18px;
  height: 30px;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.-scroll-away .title__text span {
  display: inline;
  font-size: inherit;
  margin-left: 0.2em;
}

@media (orientation: portrait) {
  .title__text {
    padding-left: 0;
    padding-right: 0;
    width: 100vw;
    font-size: 10vw;
  }
  .title__text span {
    font-size: 4.15vw;
  }
  .-scroll-away .title__text {
    display: none;
  }
}

.landing-page {
  overflow: hidden;
  transition: transform 1s ease;
  z-index: 1000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.landing-page.-scroll-away {
  transform: translateY(calc(-100% + 30px));
}

@media (orientation: portrait) {
  .landing-page.-scroll-away {
    transform: translateY(-100%);
  }
}
