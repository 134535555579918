html {
  box-sizing: border-box;
}

body {
  font-family: $font-default;
}

* {
  box-sizing: inherit;
}

img {
  max-width: 100%;
}
