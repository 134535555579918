.landing-page {
  overflow: hidden;
  transition: transform 1s ease;
  z-index: 1000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  &.-scroll-away {
    transform: translateY(calc(-100% + 30px));
    @media (orientation: portrait) {
      transform: translateY(-100%);
    }
  }
}
