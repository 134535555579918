.title {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  &__text {
    background-color: $green;
    color: $white;
    //font-size: 100vw * (80 / 800);
    font-size: 7.4vw;
    font-weight: 400;
    padding: 6vh 6vw;
    text-align: center;
    span {
      display: block;
      //font-size: 100vw * (30 / 800);
      font-size: 3vw;
    }
  }
  @at-root .-scroll-away & {
    top: auto;
    &__text {
      align-items: center;
      display: flex;
      font-size: 18px;
      height: 30px;
      justify-content: center;
      margin: 0;
      padding: 0;
      width: 100%;
      span {
        display: inline;
        font-size: inherit;
        margin-left: 0.2em;
      }
    }
  }
  @media (orientation: portrait) {
    &__text {
      padding-left: 0;
      padding-right: 0;
      width: 100vw;
      font-size: 10vw;
      span {
        font-size: 4.15vw;
      }
    }
    @at-root .-scroll-away &__text {
      display: none;
    }
  }
}
