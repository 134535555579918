.page-header {
  background-color: $green;
  color: $white;
  display: none;
  height: 16vh;
  padding: 0 20px;
  text-align: center;
  h1 {
    margin: 0;
  };
  .title-large,
  .title-small {
    display: block;
  }
  .title-large {
    //font-size: 43px;
    font-size: 100vh * (43 / 800);
  }
  .title-small {
    // font-size: 16px;
    font-size: 100vh * (16 / 800);
  }
  @media (orientation: portrait) {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
