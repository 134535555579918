.landing {
  overflow: hidden;
  &__grid {
    display: grid;
    height: 100vh;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    overflow: hidden;
    width: 100%;
    @media (orientation: portrait) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }
  }
  &__cell {
    filter: grayscale(0%);
    position: relative;
    transition: filter 0.5s;
    &.-effect {
      filter: grayscale(100%);
    }
  }
  img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
