.res-slide-info {
  background-image: linear-gradient(to top, $grey-dk, transparent 40%);
  color: $white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  @media screen and (orientation: portrait) {
    background-image: linear-gradient(to top, $grey-dk, transparent 75%);
  }
  &__h {
    font-weight: 400;
    font-size: 100vh * (32 / 800);
    line-height: 1.25;
    margin: auto 0 0;
    @media (orientation: portrait) {
      font-size: 100vh * (18 / 800);
    }
  }
  &__h-sub {
    font-weight: 400;
    font-size: 100vh * (24 / 800);
    line-height: 1.25;
    margin: 0.5em 0 0;
    @media (orientation: portrait) {
      font-size: 100vh * (16 / 800);
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 7.5%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  &__meta {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 100vh * (20 / 800);
  }
  &__year {
    color: $orange;
    font-size: 100vh * (32 / 800);
    font-weight: 400;
    margin: 0;
    @media (orientation: portrait) {
      font-size: 100vh * (20 / 800);
    }
  }
  &__ext-link,
  &__back,
  &__btn {
    background-color: transparent;
    border-radius: 0;
    border: 1px solid currentColor;
    color: $white;
    cursor: pointer;
    font-family: $font-alt;
    font-size: 100vh * (16 / 800);
    padding: 0.5em 1.5em;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
    @media (orientation: portrait) {
      font-size: 100vh * (12 / 800);
    }
    &:hover {
      background-color: $white;
      color: $grey-dk;
      border-color: $white;
    }
  }
  &__more {
    background-color: $grey-md;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    text-align: center;
    transform: translateY(100%);
    transition: all 0.4s;
    padding: 7.5%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    @media (orientation: portrait) {
      justify-content: flex-start;
      flex-direction: row;
      background-color: transparent;
      color: $black;
      opacity: 1;
      text-align: left;
      transform: translateY(100%);
    }
    @at-root .-show-more & {
      opacity: 1;
      transform: translateY(0);
      @media (orientation: portrait) {
        transform: translateY(100%);
      }
    }
  }
  &__text {
    font-size: 100vh * (24 / 800);
    opacity: 0;
    transition: opacity 1s 0.4s ease-out;
    @at-root .-show-more & {
      opacity: 1;
    }
    @media (orientation: portrait) {
      font-size: 100vh * (20 / 800);
      opacity: 1;
      transition: opacity 0.4s 0s ease-out;
    }
  }
  &__btn {
    @media (orientation: portrait) {
      display: none;
    }
  }
  &__back-control {
    margin-top: 100vh * (100 / 800);
    @media (orientation: portrait) {
      display: none;
    }
  }
}
