$font-default: 'Roboto', sans-serif;
$font-alt: 'Noto Sans', sans-serif;
$font-size-heading: 24;


// colours
// ---------------------------------------------
$orange:  #ED6B06;
$green:   #77A22F;

$white:   #fff;
$black:   #000;
$grey-dk: #353434;
$grey-md: #8A8A8A;
