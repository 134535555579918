
.swiper {
  padding: 5vh 0;
  //overflow-y: visible;
  //overflow-x: hidden;
  @media (orientation: portrait) {
    padding-bottom: 37vh;
  }
}
.swiper-slide {
  height: 80vh;
  max-width: 720px;
  max-height: 720px;
  overflow: hidden;
  width: 80vh;
  @media (orientation: portrait) {
    height: 40vh;
    overflow: visible;
    width: 40vh;
  }
}
.swiper-slide img {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  color: $orange;
  transition: transform;
  @media (orientation: portrait) {
    display: none;
  }
}
.-revealed .swiper-button-next {
  animation: 0.25s ease-in-out 0s 12 alternate wiggle;
}
// firefox click debug
@-moz-document url-prefix() {
  .swiper-wrapper {
    width: 0;
  }
  //.swiper-slide-active {
  //  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) !important;
  //}
}
@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-8px);
  }
}
